@font-face {
    font-family: 'Open Sans';
    src: url('ConthraxSb-Regular.eot');
    src: local('ConthraxSb-Regular'),
        url('ConthraxSb-Regular.eot?#iefix') format('embedded-opentype'),
        url('ConthraxSb-Regular.woff2') format('woff2'),
        url('ConthraxSb-Regular.woff') format('woff'),
        url('ConthraxSb-Regular.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

