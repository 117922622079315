.slider{
    height: 734px;
    /* padding: 20px 0px; */
    overflow: hidden;

}
.slider .container{
    position: relative;
    height: 95%;
    
}

.slider-left_fon{
    position: absolute;
    left: -90px;
    top: 0;
    bottom: 0;
    background: no-repeat;
    background-image: url(/public/img/slider/fon_left.png);
    
    height: 100%;
    width: 100%;
    opacity: 0.8;
}
.slider-left__text-block {
    position: absolute;
    left: 96px;
    top: 132px;
    color: white;
    height: 600px;
    width: 50%;
}
.slider-left__text-block h2{
    font-family: "Roboto";
    font-size: 70px;
    font-weight: 500;
    line-height: 85px;
    letter-spacing: 0em;
    text-align: left;
    text-shadow: 0px 4px 4px #00000040;
    text-transform: uppercase;
}
.slider-left__text_mini_box{
    display: flex;
    gap: 12px;
    align-items: center;
    height: min-content; 
}
.slider-left__text_mini {
    font-family: "Open Sans";
    font-size: 36px;
    font-weight: 400;
    line-height: 47px;
    letter-spacing: -0.005em;
    text-align: left;
    color: #363844;
    padding: 5px;
    background: #FCFCFCCC;
    border-radius: 10px;
    height: min-content;
    box-shadow: -2px 3px 10px #00000069;
}
/* .slider-left__text_mini:nth-child(2) {
    margin-top: 84px;
    font-size: 28px;
    color: #ff552f;
} */
.slider-item{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

}
.slider-left__text_opis{
    font-family: "Roboto";
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 40px;
}
.slider-item_img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 1000ms;
    width: 100%;
}
.slider-item_img.active{
    opacity: 1;
}

.slider-item_img_big{
    height: 95%;
    border-radius: 50px;
    height: auto;
    width: 100%;
}

.slider-item_img_min{
    position: absolute;
    bottom: -43px;
    right: 100px;
}
.bi {
    bottom: -74px;
    right: 33px;
}




.slider-left__text_items{
    height: 59%;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.slider-left__text_item{
    position: absolute;
    left: -999px;
    transition: 500ms;
}
.slider-left__text_item.active{
    left: 0;
}
.slider-chek{
    position: absolute;
    bottom: 30px;
    left: 150px;
    padding: 20px;
    display: flex;
}
.slider-chek_number{
    color: #A5A5A5;
    font-size: 24px;
    transition: 500ms;
    font-size: 40px;
    text-shadow: 0px 4px 4px #00000040;
    cursor: pointer;
}
.slider-chek_number.active{
    color: #FEFEFE;
}
.slider-chek_line{
    display: block;
    position: relative;
    width: 50px;
    transition: 300ms;
    top: -11px;
}
.slider-chek_line.active{
    width: 80px;

}
.slider-chek_line:after {
	content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: 12px;
    width: 0;
    height: 2px; /* Высота линии */
    background-color: black; /* Цвет подчеркивания при исчезании линии*/
    /* transition: width 0.5s; Время эффекта */
}

.slider-chek_line.active:after {
	content: "";
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    bottom: 12px;
    height: 2px; /* Высота линии */
    background-color: #FEFEFE; /* Цвет подчеркивания при появлении линии*/
    transition: width 5s;  /* Время эффекта */
}
.slider_title{
    color: #FEFEFE;
}
/* katalog */

.katalog {
    margin-bottom: 100px;
    margin-top: 100px
}

.katalog-chek {
    display: flex;
    gap: 30px;
    justify-content: flex-end;
    width: 100%
}

.katalog-chek_box {
    cursor: pointer;
    height: 61px;
    position: relative;
    display: flex;
    width: 84px;
    align-items: flex-end;
}


.katalog-chek_box:after {
    background: #a5a5a5;
    bottom: -10px;
    content: "";
    height: 5px;
    left: 0;
    opacity: .5;
    position: absolute;
    width: 84px
}

.katalog-chek_box.active:after {
    background: #ff552f;
    opacity: 1
}

.katalog-list {
    grid-gap: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    margin: 50px 0;
    transition: 5s
}

.katalog-list_item {
    color: #363844;
    cursor: pointer;
    height: auto;
    position: relative;
    width: 100%;
    z-index: 2
}

.katalog-list_item:hover h3 {
    color: #ff552f
}

.katalog-list_item:after {
    border-image: linear-gradient(90deg, #ff552f 14%, #f5f5f5 0, #f5f5f5 84%, #ff552f 0);
    border-image-slice: 1;
    border-top: 3px solid transparent;
    top: 0
}

.katalog-list_item:after, .katalog-list_item:before {
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    content: "";
    height: 15%;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: .5s;
    width: 99%;
    z-index: 1
}

.katalog-list_item:before {
    border-bottom: 3px solid transparent;
    border-image: linear-gradient(90deg, #ff552f 14%, #f5f5f5 0, #f5f5f5 84%, #ff552f 0);
    border-image-slice: 1;
    bottom: 0
}

.katalog-list_item:hover:after, .katalog-list_item:hover:before {
    opacity: 1
}

.katalog-list_item__img {
    align-items: center;
    background-color: #efefef;
    display: flex;
    flex-direction: column;
    height: 251px;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    width: 100%
}

.katalog-list_item__img img {
    height: auto;
    width: 100%
}

.katalog-list_item__text {
    align-items: center;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    height: 124px;
    justify-content: center;
    text-align: center
}

.katalog-list_item h3 {
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    transition: .5s;
    width: 73%
}

.katalog-list_item p {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 300
}

.katalog-button {
    align-items: center;
    border: 1px solid #363844;
    display: flex;
    height: 60px;
    justify-content: center;
    margin: 0 auto;
    transition: .3s;
    width: 312px
}

.katalog-button:hover {
    background: #ff552f;
    color: #efefef
}
/* gallari */
.gallari{
    margin-bottom:50px ;
}
.gallari_items{
    margin-top: 100px;
}
.gallari_item img {
    width: 100%;
    height: auto;
    border-radius: 40px;
}
.gallari_item_name{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
    text-transform: uppercase;
    color: #363844;
}
.gallari_item_name::after{
    content: "";
    width: 40px;
    background: #A5A5A5;
    height: 3px;
    display: block;
}
/* info */
.info{
    margin-top: 150px;
    margin-bottom: 60px;

}
.info .container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.info-body{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
    margin:100px 0px;

}
.info-body_left li{
    list-style:none ;
    font-family:"Open Sans" ;
    font-weight: 400;
    font-size: 18px;
    padding-top: 20px;
    position: relative;
    text-align: justify;
    max-width: 585px;
}
.info-body_left li::before{
    position: absolute;
    top: 20px;
    left: -40px;
    width: 22px;
    height: 22px;
    background: #FF552F;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    border-radius: 5px;
    color: #EFEFEF;
}
.info-body_left li:nth-child(1):before{
    content: "1";
    
}
.info-body_left li:nth-child(2):before{
    content: "2";
    
}
.info-more{
    margin-top: 30px;
    border: none;
    color: #FF552F;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 22px;
    gap: 10px;
    z-index: 1;
    position: relative;
}
.info-more span{
    
    z-index: 2;
    transition: 500ms;
    padding-left: 10px;
}
.info-more:hover span{
    
    color: #EFEFEF;
}
.info-more::after{
   content: "";
   width: 19px;
   height: 23px;
   border: 1px solid #4B4B4B;
   display: block;
   border-left: none;
   position: relative;
   transition: 600ms;
}
.info-more:hover:after{
    border-color: #FF552F;
}
.info-more::before{
    content: "";
    position: absolute;
    right: 1px;
    top: 0px;
    height: 23px;
    width: 0;
    background: #FF552F;
    transition: 500ms;
    z-index: 1;
 }
 .info-more:hover::before{
    width: 100%;
 }


 .info-right_img{
    background: no-repeat;
    background-image: url(/public/img/fons1.jpg);
    width: 100%;
    height: 340px;
 }
.info-right_text{
    padding-top: 30px;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 24.3px;
    max-width: 550px;
    text-align: justify;
}
/* lineKlient */
.lineKlient{
    height: 135px;
    width: 100%;
    background: #fff;
}
.lineKlient .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    gap: 80px;
}
.lineKlient img{
    width: auto;
}
:root {
    --marquee-width: 100%;
    --marquee-height: 50px;
    /* --marquee-elements: 12; */ /* defined with JavaScript */
    --marquee-elements-displayed: 7;
    --marquee-element-width: calc(var(--marquee-width) / var(--marquee-elements-displayed));
    --marquee-animation-duration: calc(var(--marquee-elements) * 6s);
}
  
  .marquee {
    width: var(--marquee-width);
    height: var(--marquee-height);
    /* background-color: #111; */
    color: #eee;
    overflow: hidden;
    position: relative;
    margin: 20px 0;
  }
  .marquee:before, .marquee:after {
    position: absolute;
    top: 0;
    width: 10rem;
    height: 100%;
    content: "";
    z-index: 1;
  }
  .marquee:before {
    left: 0;
    /* background: linear-gradient(to right, #111 0%, transparent 100%); */
  }
  .marquee:after {
    right: 0;
    /* background: linear-gradient(to left, #111 0%, transparent 100%); */
  }
  .marquee-content {
    list-style: none;
    height: 100%;
    display: flex;
    animation: scrolling var(--marquee-animation-duration) linear infinite;
  }
  /* .marquee-content:hover {
    animation-play-state: paused;
  } */
  @keyframes scrolling {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-1 * var(--marquee-element-width) * var(--marquee-elements))); }
  }
  .marquee-content li {
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    flex-shrink: 0;
    width: var(--marquee-element-width);
    max-height: 100%;
    font-size: calc(var(--marquee-height)*3/4); /* 5rem; */
    white-space: nowrap;
  }
  
  .marquee-content li img {
    width: 150px;
    height: auto;
  }
  
  /* @media (max-width: 600px) {
    html { font-size: 12px; }
    :root {
      --marquee-width: 100vw;
      --marquee-height: 16vh;
      --marquee-elements-displayed: 3;
    }
    .marquee:before, .marquee:after { width: 5rem; }
  } */






/* stagesWork */
.stagesWork{
    padding: 100px 0;
    
}
.stagesWork .container{
    padding: 100px 0;
    background: no-repeat;
    background-image: url(/public/img/stagesWork.jpg);
    background-size: cover;
    border-radius: 30px;
}
.stagesWork h2{
    color: #FFF;
    text-align: center;
}
.stagesWork-items{
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    width: 100%;
    justify-items: end;
    z-index: 1;
    row-gap:  100px;
    padding: 0px 100px;
}
.stagesWork-item {
    width: 570px;
    z-index: 3;
    color: #EFEFEF;
    height: 181px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.stagesWork-item p{

    font-family: "Roboto";
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;

    
}
.stagesWork-item_number h3{
    width: 373px;
height: 29px;
text-transform: uppercase;


font-family: "Roboto";
font-size: 29px;
font-weight: 700;
line-height: 26px;
letter-spacing: 0em;
text-align: left;

}
.stagesWork-item_number {
    width: 81%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    padding-bottom: 15px;
}
.stagesWork-item_number::after {
    content: attr(data-type);
    font-weight: 200;
    font-size: 70px;
    position: relative;
    /* font-style: italic; */
    top: 17px;
    left: -4px;
    /* right: -30px; */
    -webkit-text-stroke: 2px rgba(252,252,252,.5);
    -webkit-text-fill-color: rgba(0,0,0,0);
}
.stagesWork-item_text {
    width: 98%;
    height: 80%;
    position: relative;
    padding: 10px;
}
.stagesWork-item_text::after {
    content: "";
    position: absolute;
    border-top-left-radius: 25px;
    border-top: 2px inset #fff;
    border-left: 2px inset #FFF;
    left: 0;
    top: 0;
    height: 27%;
    width: 88%;
}
/* Новости */
.news{
    margin: 100px 0px;

}
.news-body{
    margin-top: 30px;
   
}
.news-body_list{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 30px;
    margin-bottom: 30px;
}

.news-item {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 360px;
    position: relative;
    border-radius: 30px;
    padding: 0px 50px;
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}

.news-item::after{
    content: "";
    position: absolute;
    background: #363844ce;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 300ms;
}
.news-item::before{
    content: url(/public/img/strel.svg);
    position: absolute;
    width: 50px;
    height: 20px;
    right: 50px;
    transition: 300ms;
    bottom: 20px;
    z-index: 5;
    display: flex;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    color: #fff;
}
.news-item:hover:before{
    content:"ЧИТАТЬ" url(/public/img/strel.svg);
    
    bottom: 33px;
    -webkit-animation: 2s ease-in-out infinite rig2;
	animation: 2s ease-in-out infinite rig2;
}
.news-item div{
    transition: 300ms;
}
.news-item:hover div{
    opacity: 0;

}
@-webkit-keyframes rig2 {
    0% {
        right:  50px;
	}
    50%{
        right: 40px;
    }
    100%{
        right: 50px;

    }
	
}

@keyframes rig2 {
    0% {
        right:  50px;
	}
    50%{
        right: 40px;
    }
    100%{
        right: 50px;

    }
	
}
.news-item:hover:after{
    background: #36384400;

}
.news-item:hover .news-item_date{
    color: #fff;

}
.news-item_text{
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 16px;
    line-height: 20.8px;
    color: #FCFCFC;
    font-family: "Roboto";
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;

}
.news-item_content {
    /* margin-left: 65px; */
    position: relative;
    z-index: 5;
}
/* .news-item_content::after{
    content: "";
    position: absolute;
    width: 10%;
    height: 99%;
    border: 2px solid #FF552F;
    border-top: none;
    border-right: none;

    top: 0;
    left: -20px;
} */
.news-item_date{
    font-family: "Open Sans";
    padding: 0px 20px 5px 0px;
    font-weight: bold;
    width: -moz-fit-content;
    width: fit-content;
    color: #b3b1b1;
    transition: 300ms;
}
.news_button_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 140px;
}
.news_button{
    width: 312px;
height: 60px;
border-radius: 2px;
border: 1px solid ;

}
.news-body_kontact{
    border: 2px solid #FF552F;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
.news-body_kontact p{
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 200px;
}
.news-body_kontact button{
    width: 190px;
height: 40px;

}

/* opros */
.opros{
    margin: 40px 0px;
}
.opros .container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.opros-body {
    border-radius: 25px;
    background: #F5F5F5;
    padding: 36px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.opros-body_items{
    display: flex;
}
.opros-body_item{
    display: flex;
    flex-direction: column;
    gap: 25px;
    transition: 300ms;
    width: 0%;
    overflow: hidden;
    height: 230px;
}
.opros-body_item.active{
    width: 100%;

}
.end{
    display: flex;
    align-items: center;
    justify-content: center;
}
.end h3{
    text-transform: uppercase;
}
.redopr{
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: #FF552F;
cursor: pointer;
}
.opros-body button{
    width: 194px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border: 1px solid #363844;
    border-radius: 25px;
    transition: 300ms;
    position: relative;
}
.opros-body_button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.opros-body_radio {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.opros-body_radio label{
    cursor: pointer;
}
.opros h2{
    margin-bottom: 25px;
}
.next{
    background: #363844;
    color: #F5F5F5;
}
.next:hover{
    background: #F5F5F5;
    color: #363844;
}
.move{
    background: none;
    color: #363844;
}
.move:hover{
    background: #363844;
    color: #F5F5F5;

}
.next:hover::before {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #363844;
    border-bottom: none;
    border-right: none;
    position: absolute;
    transform: rotateZ(132deg);
    right:  55px;
    top: 16px;
    transition: 300ms;
    -webkit-animation: 1.5s ease-in-out infinite rig;
	animation: 1.5s ease-in-out infinite rig;
}
.move:hover::before {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #F5F5F5;
    border-bottom: none;
    border-right: none;
    position: absolute;
    transform: rotateZ(316deg);
    left: 55px;
    top: 16px;
    transition: 300ms;
    -webkit-animation: 2s ease-in-out infinite lef;
	animation: 2s ease-in-out infinite lef;
}
@-webkit-keyframes lef {
    0% {
        left: 20px;
	}
    50%{
        left: 10px;
    }
    100%{
        left: 20px;

    }
	
}

@keyframes lef {
    0% {
        left: 20px;
	}
    50%{
        left: 10px;
    }
    100%{
        left: 20px;

    }
	
}


@-webkit-keyframes rig {
    0% {
        right:  20px;
	}
    50%{
        right: 10px;
    }
    100%{
        right: 20px;

    }
	
}

@keyframes rig {
    0% {
        right: 20px;
	}
    50%{
        right: 10px;
    }
    100%{
        right: 20px;

    }
	
}
.katalog-list_button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.katalog-list_button button{
    border: 1px solid #363844;
    /* background: #363844; */
    width: auto;

    /* height: 50px; */
    border-radius: 20px;
    padding: 15px 50px;

    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-transform: uppercase;
    transition: 300ms;
    color: #fff;


}
.katalog-list_button button span{
    color: #fff !important;

}

.katalog-list_button button:hover {
    transition: 300ms;
    background: #FF552F;
    color: #EFEFEF;
}
