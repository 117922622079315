@font-face {
    font-family: 'Disket-Mono';
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
       
        url('Disket-Mono-Regular.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Disket-Mono';
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
        
        url('Disket-Mono-Bold.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}