.kompani{

}
.kompani_info{
    display: grid;
    grid-template-columns:1fr 2fr;
    margin: 50px 0px;
}
.kompani_info img{
    width: 100%;
    height: auto;
    border-radius: 35px;
}
.kompani_info_text{
    padding-left: 35px;


    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.kompani_info_text h2{
    font-size: 24px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: #363844;
    text-transform: none;
    margin-bottom: 30px;
}
.opros-body_button.non{
    display: none;
}
.kompani_info_text p{
font-size: 18px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: justified;
    margin-top: 18px;
}


.kompani_advantages_box{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 27px;
    margin: 50px 0px;
}
.advantages{
    border-radius: 30px;
    background: #F6F6F6;
    padding: 27px;
}
.advantages h3{
    font-size: 24px;
    font-weight: bold;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: #363844;
    margin-bottom: 20px;
}
.advantages p{
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: justified;

}
.kompani_advantages_ps{
    width: 100%;
    
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    margin: 50px 0px;
}
.gen{
    
    font-size: 14px !important;
}
