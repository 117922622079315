.newsPages_body{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding: 50px 0px;
}
.newsPages_prev{
    width: 100%;
    height: auto;
    margin: 20px 0px;
    border-radius: 25px;
}
.newsPages_dates{
    height: 20px;
}
.newsPages_textBig{
    padding-top: 40px;
    width: 95%;
    font-size: 24px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;

}
.newsPages_textBig span{
    margin-top: 10px;
    display: block;
}
.newsPages_gallari{
    display: flex;
    gap: 10px;
    margin: 50px 0;
    justify-content: center;
    flex-wrap: wrap;
}
.newsPages_gallari img{
    height: 200px;
    border-radius: 5px;
    cursor: pointer;
}
.popup_img{
    width: 0%;
    height: 100vh;
    position: fixed;
    left: -100px;
    top: 0;
    z-index: 999999;
    background: #00000038;
    transition: 300ms;
    display: flex;
    align-items: center;
    justify-content: center;


}
.popup_img div{
    width: 60%;
    height: auto;
    padding: 25px;
    background: #fff;
    border-radius: 25px;
}
.popup_img img{
    width: 100%;
    height: auto;
    border-radius: 25px;

}
.popup_img.active{
    width: 100%;
    left: 0;
}



