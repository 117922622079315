.avto_body {
    display: grid;
    grid-template-columns: 60% 40%;
    margin-bottom: 50px;
}

.avto_body_gallari {
    position: sticky;
    top: 78px;
    width: 100%;
    height: 750px;
}

.avto_body_gallari img {
    width: 100%;
    height: auto;
    border-radius: 25px;
    
}
.avto_body_gallari_list img{
    max-height: 150px;
}
.avto_body_gallari_prev img{
    position: absolute;
    bottom: 0;
    left: 0;

    border-radius: 25px;
}
.avto_body_gallari_prev{
    height: 600px;
    overflow: hidden;
    position: relative;

}

.avto_body_gallari_lists {
    display: flex;

}

.avto_body_gallari_list{
    cursor: pointer;
    background: #fff;
    padding: 5px;
    margin: 0px auto;
}

.avto_body_info {
    padding-left: 25px;
}

.avto_title {
    text-align: start;
}

.avto_opis , .avto_opis p{
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: justified;
    margin: 35px 0px;
}
.avto_opis div {
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: justified;
}
.a1 div{
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
}
.a1 div:first-child {
    float: left;
    font-weight: bold;

  }
  
  .a1 div:last-child {
    float: right;
  }
  
  .a1 {
    overflow: hidden;
    line-height: 1.2em;
    overflow: hidden;
    line-height: 1.2em;
    padding: 5px 0px;
    border-bottom: 1px solid #aaa;
    margin-bottom: 5px;
  }
  
  /* .a1:after {
    content: "";
    display: block;
    overflow: hidden;
    padding-top: 1em;
    height: 1px;
    box-sizing: content-box;
    background: #000;
    background-clip: content-box;
  }
  
  .a1 div:first-child:after, .a1 div:last-child:before {
    content: "";
    display: inline-block;
    width: .2em;
  } */



.avto_body_info .but{
    margin-top: 50px;
    border: 1px solid #363844;
    background: #363844;


    height: 60px;
    border-radius: 20px;


    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-transform: uppercase;
    color: #fff;
}
.avto_body_info .but:hover {
    transition: 300ms;
    background: #FF552F;
    color: #EFEFEF;
    border: none;
}

.avto_body_gallari_prev{
    border-radius: 25px;
}
